import { Component, OnInit, Input } from '@angular/core';
import { ModalController, MenuController } from '@ionic/angular';

@Component({
    selector: 'page-dymanic',
    templateUrl: 'page-dymanic.page.html'
})

export class PageDymanicPage implements OnInit {

    @Input() titulo;
    @Input() conteudo;
    constructor(
        private menuCtrl: MenuController,
        public modalCtrl: ModalController
    ) { }

    ngOnInit() { 
        this.menuCtrl.close().then( () => { this.menuCtrl.enable(false)})
    }

    fechaModal(){
        this.menuCtrl.enable(true);
        this.modalCtrl.dismiss()
    }
}