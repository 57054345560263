import { FilterDateEvent } from './filterDateEvent.pipe';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import { TimeFormatPipe } from './timeFormt.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';


@NgModule({
    declarations: [SafeHtmlPipe, TimeFormatPipe, FilterDateEvent],
    imports: [IonicModule],
    exports: [IonicModule, SafeHtmlPipe, TimeFormatPipe, FilterDateEvent],
    entryComponents: [],
    providers: [ ],
})

export class PipesModule {}