import { PageDymanicPage } from './pages/page-dynamic/page-dynamic.page';
import { Component } from '@angular/core';

import { Platform, MenuController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

const URL = 'http://premoldados.online/admin/Blk_API_Dados/Blk_API_Dados.php';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  menuPage: Pages[]; 

  constructor(
    public http: HttpClient,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.loadingMenu();
    });
  }

  loadingMenu() {
    this.http.post<Pages[]>(URL + '?opt=GetPages', {}).subscribe(
      result => {
        console.log('result', result);
        this.menuPage = result;
      }
    );

  }

  abrirModal(menu) {
    console.log(menu)
    this.menuCtrl.close().then( async () => {

       const modal = await this.modalCtrl.create({
          component: PageDymanicPage,
          componentProps: { titulo: menu.titulo , conteudo: menu.descricao  }
        });
       modal.present();
    });
  }
}
