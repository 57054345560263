import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterDateEvent',
  pure: true
})
export class FilterDateEvent implements PipeTransform {

  constructor() {}

transform(arrayFilter: Array<any>, dateSelected) {
    return arrayFilter.filter( (date) => {
        const dateStart = new Date(date.dhinicio);
        const dateFilter = new Date(dateSelected);
        return dateStart.toLocaleDateString() == dateFilter.toLocaleDateString();
    }
    )
}

}
