import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MinutesToHHMISS'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number): string {

    const mi = value * 60000
    const data = {
        seconds      : this.countSteps(mi, 1000, 60),
        minutes      : this.countSteps(mi, 60000, 60),
        hours        : this.countSteps(mi, 3600000, 24),
        days         : this.countSteps(mi, 86400000, 0)
    };

    return  this.addZero(data.hours) + ':' + this.addZero(data.minutes)  + ':' + this.addZero(data.seconds);
  }

    private countSteps(val, step, overflow){
        //if (val/step == 0) mod oveflow will return NaN, so ~~ converts it to 0
        return overflow ? (Math.floor(val / step) % overflow ) : Math.floor(val / step);
    }

    private addZero(value){
      if(value < 10){
        value = '0' + value
      }
      return value;
    }
}